import React from "react";
import styles from "./conditions.module.css";
import { useTranslation } from "react-i18next";

export default function Conditions() {
  const { t } = useTranslation("translation", { useSuspense: false });
  return (
    <section className={`container ${styles.infoSection}`}>
      <div className={styles.flex}>
        <div className={styles.dressCode}>
          <h3>{t("contact.code.title")}</h3>
          <p>&#x2714; {t("contact.code.1")}</p>
          <p>&#x2714; {t("contact.code.2")}</p>
        </div>
        <div className={styles.mapContainer}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1653.2480978765564!2d-5.006170112351874!3d34.03114077093956!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7d827735fe6e0d56!2sLa%20Noce!5e0!3m2!1sen!2s!4v1602640452315!5m2!1sen!2s"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            title="map"
          ></iframe>
        </div>
      </div>
      <div className={styles.flex} style={{ marginTop: "75px" }}>
        <div className={styles.bookingConditions}>
          <h3>{t("contact.conditions.title")}</h3>
          <p>&#x2714; {t("contact.conditions.1")}</p>
          <p>&#x2714; {t("contact.conditions.2")}</p>
          <p>&#x2714; {t("contact.conditions.3")}</p>
          <p>&#x2714; {t("contact.conditions.4")}</p>
          <p>&#x2714; {t("contact.conditions.5")}</p>
        </div>
        <div className={styles.groupOffer}>
          <h3>{t("contact.group.title")}</h3>
          <p>{t("contact.group.text")}</p>
        </div>
      </div>
    </section>
  );
}
