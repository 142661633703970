import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styles from "./contact.module.css";
import bgHero from "../../images/restaurant/carousel/1L.jpg";
import BgHero from "../BgHero";
import { useTranslation } from "react-i18next";

export default function Main() {
  const { t } = useTranslation("translation", { useSuspense: false });
  return (
    <>
      <BgHero heading={t("contact.title")} bg={bgHero} />

      <main className={`container ${styles.content}`}>
        <div>
          <h5>{t("contact.address")}</h5>
          <p>12 rue Jarirr 30000, Fes Maroc</p>
          <h5>Phone</h5>
          <p>
            00212 5 35 62 68 03 <br />
            0033 6 05 77 52 03 <br />
            00212 6 61 42 60 89
          </p>
          <h5>Email</h5>
          <p>lanocerestaurant@gmail.com</p>
          <h5>{t("contact.time")}</h5>
          <p>{t("contact.timing")}</p>
        </div>
        <form
          action="https://send.pageclip.co/Wd4mKZ4GfHwFgTZGikACZFb56vZixXYb"
          method="post"
          className={`pageclip-form ${styles.form}`}
        >
          <TextField
            className={styles.input}
            label={t("contact.name")}
            name="name"
            variant="outlined"
          />
          <TextField
            className={styles.input}
            label="Email"
            type="email"
            name="email"
            required
            variant="outlined"
          />
          <TextField
            className={styles.input}
            label={t("contact.subject")}
            name="subject"
            variant="outlined"
          />
          <TextField
            className={styles.input}
            label={t("contact.message")}
            name="message"
            variant="outlined"
            rows={5}
            multiline
            required
          />
          <Button
            variant="contained"
            type="submit"
            className={styles.submitBtn}
          >
            {t("contact.submit")}
          </Button>
        </form>
      </main>
    </>
  );
}
