import React from "react";
import Conditions from "../components/Contact/Conditions";
import Main from "../components/Contact/Main";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation("translation", { useSuspense: false });
  return (
    <>
      <Helmet>
        <title>{t("contact.title")}</title>
        <script
          src="https://s.pageclip.co/v1/pageclip.js"
          charset="utf-8"
        ></script>
        <link
          rel="stylesheet"
          href="https://s.pageclip.co/v1/pageclip.css"
          media="screen"
        />
      </Helmet>
      <Nav />
      <Main />
      <Conditions />
      <Footer />
    </>
  );
}
